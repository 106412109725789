import SignMessage from "./SignMessage";

export default function App() {
  return (

    <div className="flex flex-wrap">
      <div className="w-1/2 mx-auto">
        <SignMessage />
      </div>
      {/* <div className="w-full lg:w-1/2">
        <VerifyMessage />
      </div> */}
    </div>
  );
}
